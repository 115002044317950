import cn from 'classnames';
import { BaseProps } from 'types';

export interface NewsCardHeaderProps extends BaseProps {}

const NewsCardHeader: React.FC<NewsCardHeaderProps> = ({ className, children }) => (
  <div className={cn('news-section__header', className)}>{children}</div>
);

export default NewsCardHeader;
